<!--
 * @Author: your name
 * @Date: 2021-02-03 11:24:37
 * @LastEditTime: 2021-02-04 20:10:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /iot-product-tool/src/pages/ryProbe/afterSale/login.vue
-->
<template>
    <div class="page-probe-after-sale-login">
        <div class="bg-box">
            <div class="content">
                <div class="title">
                    <img v-if="fType == 'yingwo'" :src="ossHost + 'img/title_after_sale_login.png'" alt="">
                    <img v-if="fType == 'lexing'" :src="ossHost + 'img/title_after_sale_login_lexing.png'" alt="">
                </div>
                <div class="login-box">
                    <div class="header">请输入质检人姓名</div>
                    <div :class="{'name': true, 'error': errText}">
                        <input v-model="name" maxlength="6" @input="inputChange" type="text" placeholder="请输入姓名">
                    </div>
                    <p class="red" v-if="errText">{{errText}}</p>

                    <div class="login-btn" @click="login">登录</div>
                </div>
            </div>
        </div>
        <div class="footer-logo">
            <img :src="ossHost + 'img/logo.png'" alt="">
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    name: "login",
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            name: '',
            errText: '',
            fType: ''
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')
        let fType = vm.$strTool.filterFactoryType(uuid);
        if (vm.$route.query.uuid) {
            fType = vm.$strTool.filterFactoryType(vm.$route.query.uuid);
            fType && vm.$localStorage.setItem('uuid', vm.$route.query.uuid)
        }

        vm.fType = fType;
        if (['yingwo', 'lexing'].indexOf(fType) > -1 && name) {
            vm.name = name;
            vm.$router.replace({
                path: fType == 'lexing' ? "/ryProbe/preSale/list" : "/ryProbe/afterSale/list"
            });
        }
    },
    methods: {
        inputChange() {
            const vm = this;
            const han = /^[\u2E80-\u9FFF]+$/;
            let err = "";
            if (!vm.name) {
                err = "请输入您的名字~";
            }
            if (!han.test(vm.name)) {
                err = "请输入6个字以内中文~";
            }

            if (vm.name == 'Yudada') {
                err = ''
            }
            vm.errText = err;
        },
        login() {
            const vm = this;
            if (vm.errText) {
                Toast.fail({
                    message: vm.errText || '请输入您的名字',
                    forbidClick: true,
                    overlay: true,
                    duration: 3000
                });
                return
            }
            const { USER_NAME } = vm.$config.keys;
            const uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid');
            const fType = vm.$strTool.filterFactoryType(uuid);
            vm.$localStorage.setItem(USER_NAME, vm.name);
            vm.$route.query.uuid && vm.$localStorage.setItem('uuid', uuid);
            if (fType == "yingwo") {
                vm.$router.replace({
                    path: "/ryProbe/afterSale/list"
                });
            }

            if (['lexing', 'wangbo'].includes(fType)) {
                vm.$router.replace({
                    path: "/ryProbe/preSale/list"
                });
            }
        }
    }
};
</script>

<style lang="less" scoped>
.page-probe-after-sale-login {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 60px;
    overflow: hidden;
    .bg-box {
        width: 100%;
        height: 100%;
        background: url('https://static.yudada.com/iotProductTool/img/bg_after_sale_login.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: relative;
        padding: 0.55rem 0.7rem 60px;
        box-sizing: border-box;
        .content {
            display: flex;
            justify-content: space-between;
            .title {
                width: 0.95rem;
                img {
                    width: 100%;
                }
            }

            .login-box {
                width: 300px;
                padding: 30px 20px;
                background: #fff;
                border-radius: 10px;
                box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
                text-align: center;
                font-size: 20px;
                color: #494949;

                .header {
                    font-size: 26px;
                    padding-bottom: 20px;
                }

                .name {
                    border: 1px solid #979797;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-bottom: 30px;
                    &.error {
                        color: red;
                        border-color: red;
                    }
                    input {
                        width: 100%;
                        padding: 6px 10px;
                        border: none;
                        outline: none;
                    }
                }

                .red {
                    color: red;
                }

                .login-btn {
                    background: #209A56;
                    border-radius: 6px;
                    color: #fff;
                    line-height: 46px;
                    cursor: pointer;
                }
            }
        }
    }

    .footer-logo {
        box-sizing: border-box;
        box-shadow: 2px -3px 4px #bbb;
        padding: 10px 20px 0;
        height: 60px;
        img {
            height: 40px;
            display: block;
        }
    }
}
</style>


